// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("local-time").start()
require("@rails/activestorage").start()
require("channels")
require('inputmask')
require("trix")
require("@rails/actiontext")
require("@hotwired/turbo-rails")
require("packs/phone_mask")
require("@yaireo/tagify")
require("intl-tel-input")

import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo

import "controllers"

const components = require.context("src", true)
components.keys().forEach(components)

import "stylesheets/application.scss";
